import { Tag, Button, Space, Popconfirm } from "ant-design-vue";
export default function ({ executeCommond, delOperateRecord }) {
  const defaultValue = [
    {
      title: "类型",
      dataIndex: "operateType",
      width: "55px",
      ellipsis: true,
    },
    {
      title: "状态",
      dataIndex: "statusName",
      width: "55px",
      ellipsis: true,
    },
    {
      title: "备注",
      dataIndex: "remarks",
      ellipsis: true,
      width: "120px",
    },
    {
      title: "操作人",
      dataIndex: "empname",
      width: "55px",
      ellipsis: true,
    },
    {
      title: "操作时间",
      dataIndex: "createTime",
      width: "70px",
      customRender: ({ text }) => {
        return (
          <span title={text}>
            {text.length === 19 ? text.substr(10) : text}
          </span>
        );
      },
    },
  ];
  const operation = {
    title: "操作",
    dataIndex: "operation",
    width: "70px",
    // fixed: "right",
    customRender: ({ record }) => {
      // 1:待执行;   2:执行中;    3:成功;   4:失败;   5:停止
      return {
        1: (
          <Space>
            <Popconfirm
              title={`确定要执行么？`}
              cancelText="取消"
              okText="确定"
              onConfirm={() => {
                executeCommond({ id: record.id, commond: 1 });
              }}
            >
              <Button size="small" type="link" style="padding:0">
                执行
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`确定要作废么？`}
              cancelText="取消"
              okText="确定"
              onConfirm={() => {
                delOperateRecord({ id: record.id });
              }}
            >
              <Button size="small" type="link" danger style="padding:0">
                作废
              </Button>
            </Popconfirm>
          </Space>
        ),
        2: (
          <Popconfirm
            title={`手动停止后不能继续当前操作,确定要停止么？`}
            cancelText="取消"
            okText="确定"
            onConfirm={() => {
              executeCommond({ id: record.id, commond: 0 });
            }}
          >
            <Button size="small" type="link" danger style="padding:0">
              停止
            </Button>
          </Popconfirm>
        ),
      }[record.status];
    },
  };
  return executeCommond && delOperateRecord
    ? [...defaultValue, operation]
    : defaultValue;
}
