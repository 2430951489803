<template>
  <a-input
    v-model:value="searchValue"
    style="margin: 8px 0; flex: none"
    placeholder="设备名称/设备SN/车牌号/车号"
  />
  <div style="flex: auto; overflow-y: scroll">
    <a-directory-tree
      :tree-data="data"
      :showIcon="true"
      @select="select"
      blockNode
      v-model:selectedKeys="localSelectedKeys"
      v-model:expandedKeys="expandedKeys"
      default-expand-all
      :multiple="false"
      :selectable="true"
      virtual
      @load="loaded"
    >
      <!-- <template #switcherIcon="{ switcherCls }"
        ><down-outlined :class="switcherCls"
      />{{switcherCls}}</template> -->

      <template #icon="{ showIcon, cmp_disabled, selected }">
        <template v-if="cmp_disabled">
          <div style="display: flex; height: 24px; align-items: center">
            <KaChe style="width: 14px; height: 14px" v-if="showIcon" />
          </div>
        </template>
        <template v-else>
          <div style="display: flex; height: 24px; align-items: center">
            <KaChe
              :style="{
                color: selected ? '#fff' : '#08af4c',
                width: '14px',
                height: '14px',
              }"
              v-if="showIcon"
            />
          </div>
        </template>
      </template>
      <template #title="{ title, cmp_disabled, vehicelNo, selected, showIcon }">
        <template v-if="cmp_disabled || !showIcon">
          <span v-if="title.indexOf(searchValue) > -1">
            {{ title.substr(0, title.indexOf(searchValue)) }}
            <span style="color: #f50">{{ searchValue }}</span>
            {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
          </span>
          <span v-else>{{ title }}</span>
          <span style="margin-left: 10px" v-if="vehicelNo">离线</span>
        </template>
        <template v-else>
          <span
            v-if="title.indexOf(searchValue) > -1"
            :style="{ color: selected ? '#fff' : '#08af4c' }"
          >
            {{ title.substr(0, title.indexOf(searchValue)) }}
            <span style="color: #f50">{{ searchValue }}</span>
            {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
          </span>
          <span v-else :style="{ color: selected ? '#fff' : '#08af4c' }">{{
            title
          }}</span>
          <span
            :style="{
              color: selected ? '#fff' : '#08af4c',
              marginLeft: '10px',
            }"
            v-if="vehicelNo"
            >在线</span
          >
        </template>
      </template>
    </a-directory-tree>
  </div>
</template>
<script setup>
import KaChe from "./KaChe.vue";
import { ref, computed, defineProps, defineEmits, watch } from "vue";
const props = defineProps(["status", "value", "selected"]);
const emits = defineEmits(["update:selected", "changeNode"]);

const data = computed(() => {
  return props.value.map((item, index) => ({
    ...item,
    title: `${item.compname}(${Number.parseInt(item.onLineCount || 0)}/${
      Number.parseInt(item.onLineCount || 0) +
      Number.parseInt(item.offLineCount || 0)
    })`,
    key: `${index}`,
    selectable: false,
    children: item.producerList.map((pl, l2) => ({
      ...pl,
      key: `${index}-${l2}`,
      title: `${pl.producerName ? pl.producerName : "无名称"}(${Number.parseInt(
        pl.onLineCount || 0
      )}/${
        Number.parseInt(pl.onLineCount || 0) +
        Number.parseInt(pl.offLineCount || 0)
      })`,
      selectable: false,
      children: pl.equipList
        .map((obj, l3) => {
          const v = {
            ...obj,
            showIcon: true,
            title: obj.vehicelNo,
            key: `${index}-${l2}-${l3}`,
            cmp_disabled: [true, false][Number.parseInt(obj.status || 0) % 2],
          };
          if (!localSelectedKeys.value && !v.cmp_disabled) {
            select([v.key], { node: v });
          }
          return v;
        })
        .filter((f3) => {
          return {
            offline: f3.cmp_disabled,
            online: !f3.cmp_disabled,
            all: true,
          }[props.status];
        }),
    })),
  }));
});
const getKeys = () => {
  const node = JSON.parse(localStorage.getItem("localTreeNode") || "{}");
  if (node.key) {
    const value = [node.key];
    emits("update:selected", node);
    emits("changeNode", node);
    return value;
  }
  return [];
};
const searchValue = ref("");
const localSelectedKeys = ref(getKeys());
const expandedKeys = ref(["0", "0-0"]);

const select = (selectedKeys, { node }) => {
  // if (node.sn && node.src && !node.cmp_disabled) {
  if (node.vehicelNo) {
    emits("update:selected", node);
    emits("changeNode", node);
    localStorage.setItem(
      "localTreeNode",
      JSON.stringify({ ...node, parent: undefined })
    );
  }
};
</script>
