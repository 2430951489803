<template>
  <video ref="videoPlayer" class="video-js vjs-big-play-centered" />
</template>

<script setup>
import {
  onUnmounted,
  ref,
  defineProps,
  onMounted,
  watch,
  reactive,
  onBeforeMount,
} from "vue";
import videojs from "./txVideo.js";
let player = null; //vue3有bug 这个写成响应式数据 切换video地址会加载不出来
const videoPlayer = ref(null);
const props = defineProps({
  // 视频地址
  videoUrl: {
    type: String,
    default:
      "https://gcksc.v.kcdnvip.com/gc/hkylxs08_1/index.m3u8?BR=md&region=shanghai",
  },
  videoTypeObj: {
    type: String,
    default: "application/x-mpegURL",
  },
});

watch(
  () => props.videoUrl,
  () => {
    if (player) {
      player.src([
        {
          src: props.videoUrl,
          type: props.videoTypeObj,
        },
      ]);
      player.play();
    }
  }
);

const state = reactive({
  options: {
    // 封面图
    poster: "",
    language: "zh-CN",
    muted: false,
    // 设置自动播放
    autoplay: "muted",
    // 预加载
    preload: "none",
    liveui: "true",
    // 显示播放的控件
    controls: true,
    // bigPlayButton: false,
    notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
    controlBar: {
      playToggle: false,
      // 画中画
      pictureInPictureToggle: false,
      // 当前时间和持续时间的分隔符
      timeDivider: false,
      // 显示持续时间
      durationDisplay: false,
      // 是否显示剩余时间功能
      remainingTimeDisplay: false,
      // 是否显示全屏按钮
      fullscreenToggle: true,
    },
    html5: {
      hls: {
        withCredentials: false,
      },
    },
    loadingSpinner: true,
    fluid: false,
    customBar: true,
  },
});

onBeforeMount(() => {
  if (player) player.dispose();
});

onMounted(() => {
  player = videojs(videoPlayer.value, state.options);
  player.src([
    {
      src: props.videoUrl,
      type: props.videoTypeObj,
    },
  ]);
  player.play();
});

onUnmounted(() => {
  if (player) {
    player.dispose();
  }
});
</script>

<style scoped>
.vjs-tech {
  pointer-events: none;
}
</style>
