<template>
  <a-table
    size="small"
    rowKey="id"
    bordered
    :dataSource="props.value"
    :columns="columns"
    :pagination="false"
  >
    <template #bodyCell="{ column, text }">
      <template v-if="column.dataIndex === 'createTime'">
        <span :title="text">
          {{ text }}
        </span>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import defaultColumns from "./logColumns.js";
const emits = defineEmits(["executeCommond"]);
const props = defineProps(["value", "executeCommond", "delOperateRecord"]);
const columns = ref([
  {
    title: "类型",
    dataIndex: "operateType",
    width: "55px",
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "statusName",
    width: "55px",
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remarks",
    ellipsis: true,
    width: "120px",
  },
  {
    title: "操作人",
    dataIndex: "empname",
    width: "55px",
    ellipsis: true,
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    width: "120px",
    ellipsis: true,
  },
]);
</script>
