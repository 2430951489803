<template>
  <a-table
    size="small"
    rowKey="id"
    bordered
    :dataSource="props.value"
    :columns="columns"
    :pagination="false"
  />
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import defaultColumns from "./logColumns.js";
const emits = defineEmits(["executeCommond"]);
const props = defineProps(["value", "executeCommond", "delOperateRecord"]);
const columns = ref(
  defaultColumns({
    executeCommond: props.executeCommond,
    delOperateRecord: props.delOperateRecord,
  })
);
</script>
