<template>
  <svg width="60" height="154" viewBox="0 0 600 1550">
    <g>
      <title>{{ values.kg }}</title>
      <rect
        opacity="0.1"
        id="svg_3"
        height="1268"
        width="600"
        y="140"
        x="0"
        stroke="#000"
        fill="#000000"
      />
      <ellipse
        ry="140"
        rx="300"
        id="svg_2"
        cy="1408"
        cx="300"
        stroke="#000"
        fill="#e53c61"
      />
      <rect
        stroke-width="0"
        id="svg_6"
        :height="values.height"
        width="600"
        :y="values.y"
        x="0"
        stroke="#000"
        fill="#e53c61"
      />
      <ellipse
        ry="140"
        rx="300"
        id="svg_4"
        :cy="values.y"
        cx="300"
        stroke="#000"
        fill="#f2899d"
      />
      <ellipse
        ry="140"
        rx="300"
        id="svg_1"
        cy="140"
        cx="300"
        stroke="#000"
        fill="#f1bec5"
      />
      <text
        transform="matrix(1, 0, 0, 1, 0, 0)"
        font-weight="bold"
        xml:space="preserve"
        text-anchor="middle"
        font-size="150"
        stroke-width="0"
        id="svg_7"
        y="450"
        x="300"
        stroke="#fff"
        fill="#000"
      >
        {{ values.kg }}
      </text>
      <text
        transform="matrix(1, 0, 0, 1, 0, 0)"
        font-weight="bold"
        xml:space="preserve"
        text-anchor="middle"
        font-size="150"
        stroke-width="0"
        id="svg_8"
        y="650"
        x="300"
        stroke="#fff"
        fill="#000"
      >
        {{ values.standard }}
      </text>
      <text
        transform="matrix(1, 0, 0, 1, 0, 0)"
        font-weight="bold"
        xml:space="preserve"
        text-anchor="middle"
        font-size="200"
        stroke-width="0"
        id="svg_9"
        y="1408"
        x="300"
        stroke="#000"
        fill="#fff"
      >
        {{ values.value }} %
      </text>
    </g>
  </svg>
</template>
<script setup>
import { computed, defineProps } from "vue";
const props = defineProps(["value", "maxValue", "standard"]);
const values = computed(() => {
  const kg = Number.parseFloat(props.value || 0).toFixed(2);
  const maxValue = props.maxValue || 100;
  const value = Number.parseInt((kg * 100) / maxValue) % 100;
  const height = 12.68 * value;
  const y = 1408 - height;
  const standard = props.standard || "kg";
  return {
    value,
    height,
    y,
    kg,
    standard,
  };
});

// console.log(values.value);

// const clearTimer = () => {
//   if (state.timeId) {
//     clearInterval(state.timeId);
//   }
// };

// const startTimer = () => {
//   clearTimer();
//   state.timeId = setInterval(() => {
//     state.timeValue = Date.now().valueOf();
//   }, 10);
// };

// onMounted(startTimer);
// onUnmounted(clearTimer);
</script>
