<template>
  <svg
    t="1668072998023"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="4999"
    width="1em"
    height="1em"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    fill="currentColor"
  >
    <path
      d="M224 584.96a32 32 0 0 1-32-32V151.04a32 32 0 0 1 64 0v401.92a32 32 0 0 1-32 32zM352 596.48a32 32 0 0 1-32-32V139.52a32 32 0 1 1 64 0v424.96a32 32 0 0 1-32 32zM992 608h-224a32 32 0 0 1-32-32v-211.84a32 32 0 0 1 64 0V544h192a32 32 0 0 1 0 64z"
      p-id="5000"
    ></path>
    <path
      d="M1022.08 564.8L941.44 352a96 96 0 0 0-89.92-64H640V256a96 96 0 0 0-38.4-76.8l-82.88-62.08a96 96 0 0 0-66.24-18.88L141.44 128c-32 2.88-45.12 16-98.56 51.52A96 96 0 0 0 0 258.24v187.52a96 96 0 0 0 42.88 80L96 561.28V672H64a64 64 0 0 0-64 64v64a64 64 0 0 0 64 64h81.92A128 128 0 0 0 384 800a125.12 125.12 0 0 0-17.92-64H576v64h-96a32 32 0 0 0 0 64h113.92a128 128 0 0 0 220.16 0H928a96 96 0 0 0 96-96c0-210.56 0-195.52-1.92-203.2zM64 445.76V258.24a32 32 0 0 1 14.4-26.56C137.92 192 136.32 192 147.2 192c355.84-32 310.4-28.48 314.24-28.48 14.08 0 12.16 0 101.76 68.48A32 32 0 0 1 576 256v192a32 32 0 0 1-12.8 25.6L480 535.68c-14.08 10.56 2.24 8.32-333.44-22.08-10.56 0-9.6-1.6-68.8-41.28a32 32 0 0 1-13.76-26.56zM64 800v-64h81.92A125.12 125.12 0 0 0 128 800z m256 0a64 64 0 1 1-64-64 64 64 0 0 1 64 64z m-160-128v-92.8l288 25.92V672z m352-80.96A581.76 581.76 0 0 0 576 544v128h-64zM704 864a64 64 0 1 1 64-64 64 64 0 0 1-64 64z m256-96a32 32 0 0 1-32 32h-96a128 128 0 0 0-192-110.08V352h211.52a32 32 0 0 1 30.08 20.8L960 581.76z"
      p-id="5001"
    ></path>
  </svg>
</template>
