<template>
  <a-modal
    :visible="state.visible"
    title="操作日志"
    :maskClosable="false"
    width="750px"
    @ok="cancel"
    @cancel="cancel"
    destroyOnClose
    :footer="null"
  >
    <LogTableVue
      :value="state.tableData"
      :scroll="{
        x: '565px',
        y: '50vh',
      }"
    />
    <a-pagination
      class="pagination"
      v-model:current="state.pageinfo.current"
      :total="state.pageinfo.total"
      show-size-changer
      :defaultPageSize="state.pageinfo.defaultPageSize"
      @change="handlePageChange"
    />
  </a-modal>
</template>

<script setup>
import { HANDLE, equipAPI } from "@/Api/index.js";
import LogTableVue from "./LogTable2.vue";
import { defineExpose, reactive } from "vue";

const state = reactive({
  sn: undefined,
  visible: false,
  tableData: [],
  pageinfo: {
    current: 1,
    total: 0,
    defaultPageSize: 20,
    pageSize: 20,
  },
});

// 查询
const query = ({ page, pageSize }) => {
  const { sn } = state;
  return HANDLE.fullLoading(
    equipAPI.operateList({ sn, page, pageSize }).then(({ data }) => {
      state.pageinfo.current = data.currentPage;
      state.pageinfo.total = data.totalCount;
      state.pageinfo.pageSize = data.pageSize;
      state.tableData = data.arr;
    }, HANDLE.handleError)
  );
};

// 页码或页大小变化
const handlePageChange = (page, pageSize) => {
  query({ page, pageSize });
};

// 搜索
const search = () => {
  query({ page: 1, pageSize: state.pageinfo.pageSize });
};

const open = (data) => {
  state.visible = true;
  state.sn = data;
  search();
};

const cancel = () => {
  state.visible = false;
};

defineExpose({
  open,
});
</script>

<style scoped>
.pagination {
  width: 100%;
  margin-top: 10px;
  text-align: right;
}
</style>
