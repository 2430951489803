<template>
  <div class="page-body">
    <div class="left">
      <a-radio-group v-model:value="state.left.buttonValue" class="buttons">
        <a-radio-button value="all" class="button">
          全部({{ allLineCount }})
        </a-radio-button>
        <a-radio-button value="online" class="button">
          在线({{ onLineCount }})
        </a-radio-button>
        <a-radio-button value="offline" class="button">
          离线({{ offLineCount }})
        </a-radio-button>
      </a-radio-group>
      <LeftTreeVue
        class="tree"
        :status="state.left.buttonValue"
        :value="state.left.treeData"
        v-model:selected="state.selected"
        @changeNode="initLiveStream"
      />
    </div>

    <div class="middle" :style="{ width: videoWidth + 'px' }">
      <div class="video" :style="{ width: '100%', height: videoHeight + 'px' }">
        <!-- <video
          width="598"
          height="400"
          autoplay
          controls
          src="https://gcbdc.a.bdydns.com/gc/hkylxs08_1/index.m3u8"
        /> -->
        <RealVideoVue
          :width="videoWidth"
          :height="videoHeight"
          :videoUrl="state.videoUrl"
          v-if="state.selected && state.videoUrl"
        />
      </div>
      <div class="logs">
        <div class="title">
          <div>操作日志</div>
          <a-button
            type="link"
            size="small"
            @click="logModal.open(state.selected.sn)"
            :disabled="!(state.selected && state.selected?.sn)"
          >
            更多
          </a-button>
        </div>
        <div>
          <LogTableVue
            tableLayout="fixed"
            :value="state.logData"
            :executeCommond="executeCommond"
            :delOperateRecord="delOperateRecord"
            :scroll="{
              x: false,
              y: tableHeight,
            }"
          />
        </div>
      </div>
    </div>
    <div class="right">
      <div class="header">
        <div>实时状态</div>
        <div>
          <a>{{ 5 - state.loopTimerValue }}s后更新</a>
        </div>
      </div>
      <a-divider style="margin: 5px 0" />
      <a-row style="line-height: 32px">
        <a-col :span="12">设备状态：</a-col>
        <a-col :span="12">{{
          { 1: "在线", 0: "离线" }[state.sxtData.status]
        }}</a-col>
        <a-col :span="12">摄像头位置：</a-col>
        <a-col :span="6">{{
          // 摄像头位置反馈 0或者1，1为摄像头下潜，可观看视频。0为摄像头上升，不可观看视频
          { 1: "下位", 0: "上位" }[state.sxtData.sxtwz] || "未知"
        }}</a-col>
        <a-col :span="6"
          ><a-button
            type="link"
            :disabled="{ 1: false, 0: true }[state.sxtData.status]"
            @click="doCameraOperate"
            >{{ { 1: "上升", 0: "下降" }[state.sxtData.sxtwz] }}</a-button
          ></a-col
        >
      </a-row>
      <a-divider style="margin: 5px 0" />
      <div class="guans">
        <div
          class="guan-item"
          v-for="(item, index) in state.potsData"
          :key="index"
          :title="
            Number.parseFloat(item.currentVolume || 0).toFixed(2) +
            (item.standard || 'kg')
          "
        >
          <div class="guan-svg">
            <SVGtong
              height="105"
              :value="Number.parseFloat(item.currentVolume || 0)"
              :max-value="Number.parseFloat(item.volume || 50000)"
            />
            {{ item.additiveName }}
          </div>
          <div class="guan-name">
            <a-tag
              style="
                margin: 0px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
              "
            >
              <ShuiBeng :style="styles[item.pumpStatus]" />
              <span :style="styles[item.pumpStatus]">
                &ensp;{{ item.sortNumber }}号罐
              </span>
            </a-tag>
          </div>
        </div>
      </div>
      <a-divider style="margin: 5px 0" />
      <a-form
        layout="horizontal"
        ref="equipOperateForm"
        :model="state.formState"
      >
        <a-row :gutter="[16, 0]">
          <a-col :span="24">
            <a-form-item label="设备" required>{{
              state.selected?.sn || "未知"
            }}</a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="车号" required>{{
              state.selected?.vehicelNo || "未知"
            }}</a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="罐号" name="id" required>
              <a-select
                v-model:value="state.formState.id"
                :options="guanOptions"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="重量" name="weight" required>
              <a-input
                addon-after="KG"
                v-model:value="state.formState.weight"
                :maxlength="10"
                :precision="2"
                :controls="false"
                type="number"
                @input="changeWeight"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item>
              <a-button
                type="primary"
                block
                @click="doEquipOperate"
                style="margin-top: 10px"
                >保存</a-button
              >
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <LogModalVue ref="logModal" />
  </div>
</template>
<script setup>
import { watch, reactive, onMounted, onUnmounted, computed, ref } from "vue";
import LeftTreeVue from "./components/LeftTree.vue";
import LogTableVue from "./components/LogTable.vue";
import SVGtong from "./components/SVGtong.vue";
import ShuiBeng from "./components/ShuiBeng.vue";
import RealVideoVue from "./components/RealVideo/index.vue";
import LogModalVue from "./components/LogModal.vue";
import { HANDLE, equipAPI } from "@/Api/index.js";
import { useUserStore } from "@/store/user.js";
import { pagination } from "@/utils/index.js";
const { store } = useUserStore();
const equipOperateForm = ref(null);
const logModal = ref(null);
const videoWidth = ref(window.innerWidth - 808);
const videoHeight = ref(window.innerHeight / 2);
const tableWidth = ref(window.innerWidth - 808);
const tableHeight = ref(window.innerHeight - videoHeight.value - 184);
const styles = {
  0: {},
  1: {
    color: "green",
  },
};

const state = reactive({
  // 任务ID
  timerId: undefined,
  // 倒计时
  loopTimerValue: 0,
  // 请求状态
  loopTimerLoading: false,
  // 左侧数据
  left: {
    buttonValue: "all",
    filterValue: undefined,
    treeData: [],
  },
  // 当前左侧树选中的值
  selected: undefined,
  // 分页
  pagination: pagination(),
  // 日志数据
  logData: [],
  // 摄像头标志位
  sxtData: {
    //状态：1:在线;0:离线
    status: 0,
    //摄像头位置反馈 0或者1，1为摄像头下潜，可观看视频。0为摄像头上升，不可观看视频
    sxtwz: 0,
  },
  // 罐列表
  potsData: [],
  // 加剂表单
  formState: {
    id: undefined,
    weight: undefined,
  },
  // 视频地址
  videoUrl: undefined,
});
// 两位小数
const changeWeight = ({ target: { value: v } }) => {
  if (v) {
    const value = `${v}`;
    const index = value.indexOf(".");
    if (index >= 0) {
      state.formState.weight = value.substring(0, index + 3);
    } else {
      state.formState.weight = value;
    }
    equipOperateForm.value = state.formState.weight;
    return;
  }
  state.formState.weight = v;
  equipOperateForm.value = state.formState.weight;
};

// 保存
const doEquipOperate = async () => {
  await equipOperateForm.value.validateFields();
  const { id: potId, weight } = state.formState;
  const { empname, opId } = store;
  const { sn } = state.selected;
  HANDLE.fullLoading(
    equipAPI
      .equipOperate({ sn, potId, weight, empname, opId })
      .then(HANDLE.handleSuccess)
      .then(() => {
        initLogs();
        state.formState.id = undefined;
        state.formState.weight = undefined;
      })
      .catch(HANDLE.handleError)
  );
};

// 摄像头 上升/下降
const doCameraOperate = async () => {
  // 如果离线,不进行操作
  if ({ 1: false, 0: true }[state.sxtData.status]) return;
  const { empname, opId } = store;
  const { sn } = state.selected;
  HANDLE.fullLoading(
    equipAPI
      .cameraOperate({
        opId,
        empname,
        sn,
        status: { 1: 0, 0: 1 }[state.sxtData.sxtwz],
      })
      .then(HANDLE.handleSuccess)
      .then(() => {
        initLogs();
        initSxtbz();
      })
      .catch(HANDLE.handleError)
  );
};

// 执行
const executeCommond = (cmd) => {
  if (!cmd) return;
  HANDLE.fullLoading(
    equipAPI
      .executeCommond({ ...cmd })
      .then(HANDLE.handleSuccess)
      .then(() => {
        initLogs();
        initPot();
      })
      .catch(HANDLE.handleError)
  );
};

// 作废
const delOperateRecord = (cmd) => {
  if (!cmd) return;
  HANDLE.fullLoading(
    equipAPI
      .delOperateRecord({ ...cmd })
      .then(HANDLE.handleSuccess)
      .then(() => {
        initLogs();
        initPot();
      })
      .catch(HANDLE.handleError)
  );
};

const guanOptions = computed(() => {
  return state.potsData.map((item) => ({
    value: item.id,
    label: item.standard
      ? `[${item.sortNumber}号罐]${item.additiveName} [${item.standard}]`
      : `[${item.sortNumber}号罐]${item.additiveName}`,
  }));
});

// 树节点被选中
watch(
  () => state.selected,
  () => {
    // 加载日志数据
    initLogs();
    // 加载摄像头标志位
    initSxtbz();
    // 加载罐列表
    initPot();
    state.loopTimerValue = 1;
  }
);

// 加载视频地址
const initLiveStream = (node) => {
  if (!node) return;
  // state.videoUrl = undefined;
  return equipAPI
    .liveStream({ id: state.selected.id })
    .then((res) => {
      const {
        data: { url },
      } = res;
      state.videoUrl = url;
      return res;
    })
    .catch(() => {
      HANDLE.message.error("获取视频地址失败! 加载测试视频!");
      state.videoUrl =
        "https://gcksc.v.kcdnvip.com/gc/hkylxs08_1/index.m3u8?BR=md&region=shanghai";
    });
  // .catch(HANDLE.handleErrorTag("获取视频地址失败! 加载测试视频!"));
};

// 加载摄像头标志位
const initSxtbz = () => {
  if (!state.selected) return Promise.resolve();
  const { sn } = state.selected;
  return equipAPI
    .realStatus({ sn })
    .then((res) => {
      state.sxtData = { ...res.data };
      return res;
    })
    .catch(() => {
      return Promise.resolve();
    });
};

// 加载罐列表
const initPot = () => {
  if (!state.selected) return Promise.resolve();
  const { sn, id } = state.selected;
  return equipAPI
    .potList({ sn, id })
    .then((res) => {
      const { data, code } = res;
      if (code === 200 || code === 0) {
        state.potsData = data;
      }
      return res;
    })
    .catch(() => {
      return Promise.resolve();
    });
};

// 加载日志数据
const initLogs = () => {
  if (!state.selected) return Promise.resolve();
  const { sn } = state.selected;
  return equipAPI
    .operateList({ sn, pageNum: 1, pageSize: 100 })
    .then((res) => {
      state.logData = res.data.arr.map((item) => {
        return {
          ...item,
          status: item.operateType === "摄像头" ? 3 : item.status,
        };
      });
      return res;
    })
    .catch(() => {
      return Promise.resolve();
    });
};

const offLineCount = computed(() => {
  return state.left.treeData.length && state.left.treeData[0].offLineCount
    ? Number.parseInt(state.left.treeData[0].offLineCount)
    : 0;
});
const onLineCount = computed(() => {
  return state.left.treeData.length && state.left.treeData[0].onLineCount
    ? Number.parseInt(state.left.treeData[0].onLineCount)
    : 0;
});
const allLineCount = computed(() => {
  return offLineCount.value + onLineCount.value;
});

// 加载左侧树数据
const initTreeData = () => {
  return equipAPI.equipList({ compid: "01" }).then(({ data }) => {
    data.compid = store.compid;
    data.compname = store.compname;

    // 测试用 以下几行正式发布需要注掉 start ==============================================
    // data.onLineCount = 1;
    // data.offLineCount = 1;
    // data.producerList[0].equipList[0].status = 0;
    // data.producerList[0].equipList[0].src =
    // "https://gcbdc.a.bdydns.com/gc/hkylxs08_1/index.m3u8";
    //   "https://gcksc.v.kcdnvip.com/gc/hkts03_1/index.m3u8?BR=md&region=shanghai";
    // data.producerList[0].equipList[1].status = 1;
    // data.producerList[0].equipList[1].src =
    //   "https://gcksc.v.kcdnvip.com/gc/hkylxs08_1/index.m3u8?BR=md&region=shanghai";
    // data.producerList[0].onLineCount = 1;
    // data.producerList[0].offLineCount = 1;
    // 测试用 以上几行正式发布需要注掉 end ==============================================

    state.left.treeData = [data];
  }, HANDLE.handleError);
};

const loop = async () => {
  if (state.loopTimerValue || state.loopTimerLoading) return false;
  state.loopTimerLoading = true;
  try {
    // 加载左侧树
    const treeData = await initTreeData();
    // 加载日志
    const logsData = await initLogs();
    // 加载摄像头标志位
    const sxtData = await initSxtbz();
    // 加载罐列表
    const potData = await initPot();
    return { treeData, logsData, sxtData, potData };
  } finally {
    state.loopTimerLoading = false;
  }
};

// 停止loop
const stop = () => {
  if (state.timerId) {
    clearInterval(state.timerId);
  }
};
// 启动loop心跳
const start = () => {
  stop();
  state.timerId = setInterval(() => {
    // 每5秒更新1次
    if (!state.loopTimerLoading) {
      state.loopTimerValue = (state.loopTimerValue + 1) % 5;
    }
  }, 1000);
};
watch(
  () => state.loopTimerValue,
  async () => {
    await loop();
  },
  { deep: true, immediate: true }
);
onMounted(start);
onUnmounted(stop);
</script>

<style>
.dark-form-item {
  margin-bottom: 12px !important;
}
</style>

<style lang="less" scoped>
.page-body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-between;
  height: calc(100vh - 92px);
  background-color: #fff;
  .left {
    height: 100%;
    width: 276px;
    flex: none;
    display: flex;
    flex-flow: column;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    padding: 0;
    .buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex: none;
      .button {
        flex: auto;
      }
    }
    .tree {
      flex: auto;
    }
  }
  .middle {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-between;
    height: var(--main-page-body-height);
    margin: 0;
    flex: auto;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    width: calc(100vw - 850px);
    .video {
      background: #000;
      flex: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .logs {
      flex: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      .title {
        flex: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #000;
        font-size: 14px;
        font-weight: 800;
        height: 40px;
      }
      // flex: none;
      // height: calc(var(--main-page-body-height) - 400px);
    }
  }
  .right {
    height: 100%;
    width: 300px;
    flex: none;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    color: #000000d9;
    padding: 12px;
    font-size: 14px;
    .header {
      display: flex;
      flex-flow: row nowrap;
      align-content: center;
      line-height: 32px;
      justify-content: space-between;
    }
    .guans {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;
      height: 203px;
      width: 100%;
      .guan-item {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin: 0;
        flex: none;
        .guan-svg {
          width: 60px;
          height: 140px;
          text-align: center;
        }
      }
    }
  }
}
</style>
