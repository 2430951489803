import "video.js/dist/video-js.css";
import "./custom-video.css";
import videojs from "video.js";
import video_zhCN from "video.js/dist/lang/zh-CN.json";
import RecordRTC from "recordrtc";
import "videojs-contrib-hls";
import "videojs-flvjs-es6";

// 截图图片
import cameraImg from "@/assets/images/camera.png";
// 录制图片
import monitorImg from "@/assets/images/monitor.png";

videojs.addLanguage("zh-CN", video_zhCN);
videojs.Hls.xhr.beforeRequest = function (options) {
  return options;
};
const Component = videojs.getComponent("Component");

class CustomBar extends Component {
  constructor(player, options = {}) {
    super(player, options);
    // player 实列
    this.player = player;
    // 录像所需要的 canvas
    this.canvas = null;
    // 录像实列
    this.recorder = null;
    // 停止循环帧 需要用到的参数
    this.animationFrame = null;
    // 录像状态 false 未录像 true 录像中
    this.isRecorder = false;

    // 隐藏截图
    if (options.screenshot === false) this.hiddenEl(0);
    // 隐藏录像
    if (options.recorder === false) this.hiddenEl(1);
    this.recordDom = ` <img src="${monitorImg}" style="width:13px" /><span class="ml10">录像</span>`;
    this.recordingDom = `<i class="record-procees"></i><span class="ml10">结束</span>`;
  }
  createEl() {
    const divDom = videojs.dom.createEl("div", {
      className: "vjs-custom-bar",
      innerHTML: `
        <div  class="vjs-custom-control-bar vjs-button ac">
        <img src="${cameraImg}" style="width:13px" /><span class="ml10">截图</span>
        </div>
        <div class="mt10 vjs-custom-control-bar ac" >
          <img src="${monitorImg}" style="width:13px" />
          <span class="ml10">录像</span>
        </div>
      `,
    });
    const [screenshotDom, recordDom] = divDom.querySelectorAll("div");
    screenshotDom.onclick = () => this.screenshotHandle();
    recordDom.onclick = () => this.recordHandle(recordDom);
    return divDom;
  }
  hiddenEl(index) {
    const myDom = this.el().querySelectorAll("div")[index];
    myDom.setAttribute("style", "display:none");
  }

  // 截图
  screenshotHandle = () => {
    const fileType = "png";
    // 找到需要截图的video标签
    const video = this.player.el().querySelector("video");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height); // 图片大小和视频分辨率一致
    const strDataURL = canvas.toDataURL("image/" + fileType); // canvas中video中取一帧图片并转成dataURL
    this.downloadFile(strDataURL, "png");
  };

  // 录像
  recordHandle = (recordDom) => {
    this.isRecorder = !this.isRecorder;
    if (this.isRecorder) {
      recordDom.innerHTML = this.recordingDom;
      this.player.muted(false);
      this.player.play();
      const video = this.player.el().querySelector("video");
      const stream = video.mozCaptureStream
        ? video.mozCaptureStream(0)
        : video.captureStream(0);
      this.recorder = new RecordRTC(stream, { type: "video" });
      this.recorder.startRecording();
      this.recorderStart = new Date().toLocaleString().replaceAll("/", "-");
    } else {
      recordDom.innerHTML = this.recordDom;
      const { recorder, recorderStart } = this;
      recorder.stopRecording(function () {
        const blob = recorder.getBlob();
        const recorderEnd = new Date().toLocaleString().replaceAll("/", "-");
        let downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${recorderStart} ${recorderEnd}.webm`; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    }
  };

  // 下载
  downloadFile(blob, fileType) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = blob;
    // const time = this.parseTime(new Date())
    const time = new Date().toLocaleString().replaceAll("/", "-");
    a.download = `${time}.${fileType}`;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blob);
    }, 1000);
  }
}
// 注册 截图、录像组件
videojs.registerComponent("CustomBar", CustomBar);
// videojs.registerPlugin("CustomBar", CustomBar);

export default videojs;
